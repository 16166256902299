<template>
  <div>
    <!--begin::User-->
    <div>
      <h3 class="card-label">
        {{ $t('accounts_without_cost_center.accounts_without_cost_center') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803">
            <h6 class="my-auto text-white">{{ $t('accounts_without_cost_center.assign_cost_centers') }}</h6>
            <button type="button" class="btn btn-primary btn-sm" @click="addToRepeater">{{ $t('add_more') }}</button>
          </div>
          <div class="bg-white">
            <table class="table" @keyup.alt.enter="addToRepeater" @keyup.alt.46="deleteFromRepeater(0)">
              <thead>
              <tr>
                <th width="50%">{{ $t('accounts_without_cost_center.cost_center') }}</th>
                <th width="20%">{{ $t('accounts_without_cost_center.percentage') }}</th>
                <th width="10%">{{ $t('accounts_without_cost_center.auto') }}</th>
                <th width="20%" class="text-center">
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in data" :key="index">
                <td>
                  <treeselect
                      :options="cost_center_list"
                      :load-options="loadOptions"
                      :multiple="false"
                      :value="row.cost_center_id"
                      @input="updateValue(index, $event)"
                      :searchable="true"
                      :show-count="true"
                      :no-children-text="$t('No_sub_options')"
                      :no-options-text="$t('No_options_available')"
                      :no-results-text="$t('No_results_found')"
                      :placeholder="$t('Select')">
                  </treeselect>
                </td>
                <td>
                  <input type="number" v-model="row.percentage" class="form-control" :placeholder="$t('accounts_without_cost_center.percentage')"/>
                </td>
                <td>
                  <b-form-checkbox size="lg" v-model="row.is_auto" name="check-button" switch></b-form-checkbox>
                </td>
                <td class="text-center">
                  <v-icon class="text-danger" v-if="data.length > 1" @click="deleteFromRepeater(index)">mdi-delete</v-icon>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "from-accounts-assign-cost-center",
  data() {
    return {
      mainRoute: 'accounting/accounts-assign-cost-center',
      mainRouteDependency: 'base/dependency',
      cost_center_list: [],

      idEdit: this.$route.params.id ? this.$route.params.id : null,
      data: [],
      repeater_data: {
        cost_center_id: null,
        account_id: this.$route.params.id,
        percentage: null,
        is_auto: 0
      },
      isEditing: false,
      validation: null,

    };
  },

  methods: {
    save() {
      // if (this.isEditing) {
      //     this.update();
      // } else {
      this.create();
      // }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push('/accounting/accounts-without-cost-center');
      }).catch((error) => {
        this.$errorAlert(error);
        this.validation = error.response ? error.response.data.errors : null;
      });
    },

    // update() {
    //     ApiService.put(this.mainRoute + '/' + this.idEdit, {
    //         ...this.data,
    //     }).then((response) => {
    //         this.validation = null;
    //         this.$successAlert(response.data.message);
    //         this.$router.push('/accounting/financial-years');
    //     }).catch((error) => {
    //         this.$errorAlert(error);
    //         this.validation = error.response ? error.response.data.errors : null;
    //     });
    // },

    // getData() {
    //     ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
    //         this.isEditing = true;
    //         this.data = response.data.data;
    //     });
    // },
    addToRepeater() {
      this.data.unshift(this.repeater_data);
      this.repeater_data = {
        cost_center_id: null,
        account_id: this.$route.params.id,
        percentage: null,
        is_auto: 0
      };
    },
    getCostCenterList() {//cost_centers
      ApiService.get(this.mainRouteDependency + "/all_cost_centers").then((response) => {
        this.cost_center_list = response.data.data;
      });
    },
    deleteFromRepeater(index) {
      this.data.splice(index, 1);
    },
    loadOptions() {
    },
    updateValue(index, value) {
      this.data[index].cost_center_id = value
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.accounts_without_cost_center"), route: '/accounting/accounts-without-cost-center'}, {title: this.$t('accounts_without_cost_center.assign_cost_center')}]);
    this.getCostCenterList();

    // if (this.idEdit) {
    //     this.getData();
    // }
    this.addToRepeater();

  },
};
</script>


